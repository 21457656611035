<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <b-row>
                      <b-col lg="6" sm="6">
                  <ValidationProvider name="Farmer Id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="farmer_id"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.farmer_id')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="farmer_id"
                        v-model="payment_collection.farmer_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @change="getFarmerInfoList(payment_collection.farmer_id)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Farmer Name">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="farmer_name"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.farmer_name')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="farmer_name"
                        v-model="payment_info.farmer_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Division" vid='division_id'>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_name"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.division')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                        id="warehouse_name"
                        readonly
                        v-model="payment_info.division"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Region" vid='region_id'>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="region"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.region')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="region_id"
                        v-model="payment_info.region"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="District" vid='district_id'>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="district"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.district')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="district_id"
                        v-model="payment_info.district"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Upazila" vid='upazilla_id'>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="upazila"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.upazila')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="upazila_id"
                        v-model="payment_info.upazilla"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Warehouse Name">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_name"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.warehouse_name')}} <span class="text-danger"></span>
                      </template>
                        <b-form-input
                          readonly
                        id="warehouse_id"
                        v-model="payment_info.warehouse"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Fiscal Year" >
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.fiscal_year')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="fiscal_year"
                        v-model="payment_info.fiscal_year"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Crop Type" >
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_type"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.crop_type')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="crop_type"
                        v-model="payment_info.crop_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Crop Name" >
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_name"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.crop_name')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="crop_name"
                        v-model="payment_info.crop_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Attachment" vid="attachment">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label=" $t('warehouse_config.warehouse_attachments')"
                      label-for="warehouse_attachments"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                     {{ $t('warehouse_config.warehouse_attachments')}}
                      </template>
                      <b-form-file
                      id="warehouse_attachments"
                      v-on:change="onFileChange"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-file>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                      </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Release Date" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="release_date"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.date')}} <span class="text-danger">*</span>
                      </template>
                       <flat-pickr class="form-control"
                          id="release_date"
                          v-model="payment_collection.release_date"
                          :placeholder="$t('globalTrans.select_date')"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></flat-pickr>
                      <!-- <b-form-input
                        id="store_date"
                        type="date"
                        v-model="payment_collection.release_date"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input> -->
                      <div class="invalid-feedback d-block" v-if="errors.length">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="6">
                  <ValidationProvider name="Store Date" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_name"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.store_date')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="payment_collection.far_req_entry_id"
                        :options="applicationLists"
                        id="far_req_entry_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Quantity">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="total_quantity"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.total_quantity')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="total_quantity"
                        v-model="payment_collection.quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Present Quantity">
                    <b-form-group
                      v-show="present_quantity"
                      class="row"
                      label-cols-sm="4"
                      label-for="present_quantity"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.present_quantity_amount')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="total_quantity"
                        v-model="present_quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Present Quantity">
                    <b-form-group
                      v-show="present_quantity === 0"
                      class="row"
                      label-cols-sm="4"
                      label-for="present_quantity"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.present_quantity_amount')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="total_quantity"
                        v-model="present_quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Rent Amount">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="rent_amount"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.rent_amount')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="rent_amount"
                        v-model="payment_collection.rent_amount"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Sack Number" vid='release_sack' rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="sack_number"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('warehouse_service.release_quantity_sack')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="sack_number"
                        type="number"
                        min='1'
                        v-model="payment_collection.release_sack"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Release Quantity" vid='release_quantity' rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="total_quantity"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.release_quantity_amount')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="total_quantity"
                        type="number"
                        min="1"
                        max="present_quantity"
                        v-model="payment_collection.release_quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                   <ValidationProvider name="Remaining Quantity" vid='remain_quantity'>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="remain_quantity"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('warehouse_service.remaining_quantity_amount')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="remain_quantity"
                        v-model="payment_collection.remain_quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Paid Rent Amount" vid='paid_rent_amount'>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="paid_rent_amount"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouseFarmer.paid_rent_amount')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                       readonly
                        id="paid_rent_amount"
                        v-model="payment_collection.paid_rent_amount"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Payment Due" vid='payment_due' rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="payment_due"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouseFarmer.payment_due')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                        readonly
                        id="payment_due"
                        v-model="payment_info.payment_due"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Bank Receipt" vid='bank_receipt_no' rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="bank_reciept_no"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.bank_reciept_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="bank_reciept_no"
                        v-model="payment_collection.bank_receipt_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
                  <b-col>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { farmerList, paymentCollectionStore } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFarmerRequestData()
      Object.freeze(tmp)
      this.payment_collection = tmp
    }
    this.getFarmerList()
  },
  mounted () {
    core.index()
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    authUser () {
      return this.$store.state.Auth.authUser
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      payment_collection: {
           warehouse_id: '',
           farmer_id: '',
           far_req_entry_id: '0',
           crop_type_id: '',
           crop_id: '',
           fiscal_year_id: '',
           level_id: '',
           quantity: '',
           rent_amount: '',
           release_sack: '',
           release_quantity: '',
           paid_rent_amount: '',
           remain_quantity: '',
           bank_receipt_no: '',
           release_date: '',
           attachment: '',
           status: '0'
      },
      payment_info: {
        division: '',
        region: '',
        district: '',
        upazilla: '',
        warehouse: '',
        payment_due: '',
        crop_name: '',
        crop_type: '',
        farmer_name: '',
        level: '',
        fiscal_year: ''
      },
      rentPerUnit: '',
      attachment_two: '',
      hasQuantity: '',
      present_quantity: '',
      cropNameLists: [],
      levelList: [],
      capacity: '',
      applicationLists: [],
      district: [],
      dList: []
    }
  },
  watch: {
    // 'payment_collection.farmer_id': function (newVal, oldVal) {
    //   this.dList = this.getFarmerInfoList(newVal)
    // },
    'payment_collection.far_req_entry_id': function (newVal, oldVal) {
      this.dList = this.getFarmerRequestEntryList(newVal)
    },
    'payment_collection.release_quantity': function (newVal, oldVal) {
      this.dList = this.getRemainAmount(newVal)
    },
    'payment_collection.paid_rent_amount': function (newVal, oldVal) {
      this.dList = this.getRemainPaymentAmount(newVal)
    }
  },
  methods: {
    getFarmerRequestData () {
      return this.$store.state.WarehouseService.farmerRequests.find(item => item.id === this.id)
    },
    async getFarmerList () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(authServiceBaseUrl, farmerList).then(response => {
        if (response) {
          this.farmerList = response.data
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        } else {
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      })
    },
    getRemainAmount (releaseAmount) {
        let remaining = ''
        if (this.present_quantity === 0) {
            this.payment_collection.release_quantity = ''
            this.payment_collection.remain_quantity = '0'
        } else {
            if (this.present_quantity) {
                if (releaseAmount > this.present_quantity) {
                    this.payment_collection.release_quantity = ''
                } else {
                    this.errorMessage = ''
                    remaining = this.present_quantity - releaseAmount
                }
            } else {
                if (releaseAmount > this.payment_collection.quantity) {
                    this.payment_collection.release_quantity = ''
                } else {
                    this.errorMessage = ''
                    remaining = this.payment_collection.quantity - releaseAmount
                }
            }
            if (remaining >= 0 && this.payment_collection.quantity) {
                this.payment_collection.remain_quantity = remaining
            }
        }

        this.payment_collection.paid_rent_amount = this.rentPerUnit * releaseAmount
    },
    getRemainPaymentAmount (paidRentAmount) {
        if (paidRentAmount > this.payment_collection.rent_amount) {
            this.payment_collection.paid_rent_amount = ''
        } else {
            this.payment_info.payment_due = this.payment_collection.rent_amount - paidRentAmount
        }
    },
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
        var formData = new FormData()
        Object.keys(this.payment_collection).map(key => {
            if (key === 'attachment') {
            formData.append(key, this.attachment_two)
            } else {
            formData.append(key, this.payment_collection[key])
            }
        })
        result = await RestApi.postData(warehouseServiceBaseUrl, paymentCollectionStore, formData, config)
        this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.payment_collection.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        this.$bvModal.hide('modal-4')
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async getFarmerInfoList (farmerId = null) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-payment-collection/farmer-info/' + farmerId).then(response => {
          if (response.success) {
            if ((this.authUser.warehouse_id && this.authUser.warehouse_id === response.data[0].warehouse_id) || this.authUser.warehouse_id >= 0) {
               this.applicationLists = response.data.map(obj => {
                if (this.$i18n.locale === 'en') {
                  return { value: obj.id, text: obj.application_id + '-' + obj.store_date }
                } else {
                  return { value: obj.id, text: this.$n(obj.application_id, { useGrouping: false }) + '-' + this.$n(obj.store_date.split('-')[2], { useGrouping: false }) + '/' + this.$n(obj.store_date.split('-')[1], { useGrouping: false }) + '/' + this.$n(obj.store_date.split('-')[0], { useGrouping: false }) }
                }
                })
            } else {
              this.applicationLists = []
            }
          } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })

        this.payment_info.farmer_name = ''
        this.payment_info.division = ''
        this.payment_info.region = ''
        this.payment_info.district = ''
        this.payment_info.upazilla = ''
        this.payment_info.warehouse = ''
        this.payment_info.crop_name = ''
        this.payment_info.crop_type = ''
        this.payment_collection.quantity = ''
        this.payment_info.fiscal_year = ''
        this.payment_collection.rent_amount = ''
    },
    async getFarmerRequestEntryList (Id = null) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-payment-collection/farmer-request-entry/' + Id).then(response => {
            if (response.success) {
                var farmerId = response.data.id
                if (this.$i18n.locale === 'bn') {
                    this.payment_info.farmer_name = this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].name_bn
                    this.payment_info.division = this.$store.state.commonObj.divisionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].division_id)[0].text_bn
                    this.payment_info.region = this.$store.state.warehouse.regionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].region_id)[0].text_bn
                    this.payment_info.district = this.$store.state.commonObj.districtList.filter(upazila => upazila.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].district_id)[0].text_bn
                    this.payment_info.upazilla = this.$store.state.commonObj.upazilaList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].upazilla_id)[0].text_bn
                    this.payment_info.warehouse = this.$store.state.warehouse.warehouseInfoList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].warehouse_id)[0].text_bn
                    this.payment_info.crop_name = this.$store.state.warehouse.commodityNameList.filter(document => document.value === response.data.crop_id)[0].text_bn
                    this.payment_info.crop_type = this.$store.state.warehouse.commodityGroupList.filter(document => document.value === response.data.crop_type_id)[0].text_bn
                    this.payment_collection.quantity = response.data.total_quantity
                } else {
                    this.payment_info.farmer_name = this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].name
                    this.payment_info.division = this.$store.state.commonObj.divisionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].division_id)[0].text
                    this.payment_info.region = this.$store.state.warehouse.regionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].region_id)[0].text
                    this.payment_info.district = this.$store.state.commonObj.districtList.filter(upazila => upazila.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].district_id)[0].text
                    this.payment_info.upazilla = this.$store.state.commonObj.upazilaList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].upazilla_id)[0].text
                    this.payment_info.warehouse = this.$store.state.warehouse.warehouseInfoList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].warehouse_id)[0].text
                    this.payment_info.crop_name = this.$store.state.warehouse.commodityNameList.filter(document => document.value === response.data.crop_id)[0].text
                    this.payment_info.crop_type = this.$store.state.warehouse.commodityGroupList.filter(document => document.value === response.data.crop_type_id)[0].text
                    this.payment_collection.quantity = response.data.total_quantity
                }
                const year = this.$store.state.warehouse.fiscalYearList.filter(document => document.value === response.rent.fiscal_year_id)[0].text_bn
                var fiscalYear = this.$n(year.split('-')[0], { useGrouping: false }) + '-' + this.$n(year.split('-')[1], { useGrouping: false })
                this.payment_info.fiscal_year = fiscalYear

                this.payment_collection.crop_id = response.data.crop_id
                this.payment_collection.crop_type_id = response.data.crop_type_id
                this.payment_collection.fiscal_year_id = response.rent.fiscal_year_id
                this.payment_collection.level_id = response.data.level_id
                this.payment_collection.warehouse_id = response.data.warehouse_id

                const presentDay = new Date()
                const storeDay = new Date(response.data.store_date)
                const diffTime = Math.abs(presentDay - storeDay)
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                var month = parseInt(diffDays / 30)
                const day = diffDays % 30
                if (day <= 15) {
                    month = month + 0.5
                } else {
                    month += 1
                }
                var rantingAmount = response.rent.renting_amount
                var totalQuantity = response.data.total_quantity
                this.rentPerUnit = rantingAmount * month
                var rent = 0

                RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-payment-collection/remaining-quantity/' + farmerId).then(response => {
                  if (response.success) {
                    if (response.data.remain_quantity > 0) {
                      this.present_quantity = response.data.remain_quantity
                      rent = rantingAmount * this.present_quantity * month
                      this.payment_collection.rent_amount = rent
                    } else {
                      this.present_quantity = 0
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                  } else {
                    this.present_quantity = ''
                    rent = rantingAmount * totalQuantity * month
                    this.payment_collection.rent_amount = rent
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                  }
                })
                this.payment_collection.release_sack = ''
                this.payment_collection.release_quantity = ''
                this.payment_collection.paid_rent_amount = ''
                this.payment_collection.bank_receipt_no = ''
            } else {
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        })
    }
  }
}
</script>
