<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:body>
        <b-row v-if="!authUser.warehouse_id">
            <b-col md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_service.farmer_id')"
              label-for="farmer_id"
            >
              <b-form-input
                id="farmer_id"
                v-model="search.farmer_id"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
        <b-row v-if="authUser.warehouse_id">
            <b-col md="6" sm="12" xs="12">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="commodity_group_id"
              :label="$t('warehouse_information.warehouse_name')"
              >
              <b-form-select
                plain
                v-model="search.warehouse_id"
                :options="warehouseList"
                id="commodity_group_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service.payment_collection') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(name)="data">
                        <span class="capitalize">
                          {{ $i18n.locale === 'bn' ? farmerList.filter(document => document.username === data.item.farmer_id || document.mobile_no === data.item.farmer_id)[0].name_bn : farmerList.filter(document => document.username === data.item.farmer_id || document.mobile_no === data.item.farmer_id)[0].name}}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_id)="data">
                        <span class="capitalize">
                          {{ $n(0) }}{{ $n(data.item.farmer_id, { useGrouping: false }) }}
                        </span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(commodity_name)="data">
                      <span class="capitalize">{{ data.item.commodity_name }}</span>
                    </template>
                    <template v-slot:cell(commodity_group_name)="data">
                      <span class="capitalize">{{ data.item.commodity_group_name }}</span>
                    </template>
                    <template v-slot:cell(rent_amount)="data">
                      <span class="capitalize">{{ $n(data.item.rent_amount) }}</span>
                    </template>
                    <template v-slot:cell(paid_rent_amount)="data">
                      <span class="capitalize">{{ $n(data.item.paid_rent_amount) }}</span>
                    </template>
                    <template v-slot:cell(release_quantity)="data">
                      <span class="capitalize">{{ $n(data.item.release_quantity) }}</span>
                    </template>
                    <template v-slot:cell(remain_quantity)="data">
                      <span class="capitalize">{{ $n(data.item.remain_quantity) }}</span>
                    </template>
                    <template v-slot:cell(bank_receipt_no)="data">
                      <span class="capitalize">{{ $n(data.item.bank_receipt_no, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.pending') : $t('globalTrans.approved') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button v-b-modal.modal-7 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-eye-fill m-0"></i></b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Click to Approve" v-show="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-check"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { paymentCollectionList, paymentCollectionToggleStatus, farmerList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
          farmer_id: ''
      },
      testId: 0,
      farmerList: [],
      rows: []
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('warehouse_service.make_payment') : this.$t('warehouse_service.farmer_request') + ' ' + this.$t('globalTrans.modify')
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('warehouse_service.farmer_name'), class: 'text-center' },
          { label: this.$t('warehouse_service.farmer_id'), class: 'text-center' },
          { label: this.$t('warehouse_service.warehouse'), class: 'text-center' },
          { label: this.$t('warehouse_service.crop_name'), class: 'text-center' },
          { label: this.$t('warehouse_service.crop_type'), class: 'text-center' },
          { label: this.$t('warehouse_service.rent_amount'), class: 'text-center' },
          { label: this.$t('warehouseFarmer.paid_rent_amount'), class: 'text-center' },
          { label: this.$t('warehouse_service.release_quantity_amount'), class: 'text-center' },
          { label: this.$t('warehouse_service.remaining_quantity_amount'), class: 'text-center' },
          // { label: this.$t('warehouse_service.bank_reciept_no'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'farmer_name_bn' },
          { key: 'farmer_id' },
          { key: 'warehouse_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'commodity_group_name_bn' },
          { key: 'rent_amount' },
          { key: 'paid_rent_amount' },
          { key: 'release_quantity' },
          { key: 'remain_quantity' },
          // { key: 'bank_receipt_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'farmer_name' },
          { key: 'farmer_id' },
          { key: 'warehouse_name' },
          { key: 'commodity_name' },
          { key: 'commodity_group_name' },
          { key: 'rent_amount' },
          { key: 'paid_rent_amount' },
          { key: 'release_quantity' },
          { key: 'remain_quantity' },
          // { key: 'bank_receipt_no' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
      if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        warehouse_name: '',
        crop_id: '',
        crop_type_id: '',
        rent_amount: '',
        release_quantity: '',
        remain_quantity: '',
        bank_receipt_no: ''
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(authServiceBaseUrl, farmerList).then(response => {
        if (response.success) {
          this.farmerList = response.data
        }
      })
      await RestApi.getData(warehouseServiceBaseUrl, paymentCollectionList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomizedDataList(response.data.data))
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async toggleStatusApprove (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.deleteData(warehouseServiceBaseUrl, `${paymentCollectionToggleStatus}/${item.id}`).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Payment Approved Successfully',
            color: '#D6E09B'
          })
          // this.$socket.emit('send-notification', response.notification)
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Comments Required | Operation Failed'
          })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      })
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.approveMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatusApprove(item)
        }
      })
    },
    getCustomizedDataList (data) {
      const list = data.map(item => {
        const farmer = this.farmerList.find(obj => obj.mobile_no === item.farmer_id)
        const customItem = {
          farmer_name: farmer?.name,
          farmer_name_bn: farmer?.name_bn
        }
        return Object.assign({}, item, customItem)
      })
      return list
    }
  }
}
</script>
